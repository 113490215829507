.react-calendar {
	width: 350px;
	max-width: 100%;
	height: 100%;
	background: whitesmoke;
	border: none;
	font-family: 'Inter', sans-serif;
	line-height: 1.125em;
}

button {
	color: black;
}

.react-calendar--doubleView {
	width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}

.react-calendar button:enabled:hover {
	cursor: pointer;
}

.react-calendar__navigation {
	display: flex;
	height: 44px;
	margin-bottom: 1em;
}

.react-calendar__navigation button {
	min-width: 44px;
	background: none;
}

.react-calendar__navigation button:disabled {
	background-color: whitesmoke;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	background-color: #def0e6;
}

.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font: inherit;
	font-size: 0.75em;
	font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font: inherit;
	font-size: 0.75em;
	font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
	color: #163020;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
	color: #163020;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
}

.react-calendar__tile {
	max-width: 100%;
	padding: 10px 6.6667px;
	background: none;
	text-align: center;
	line-height: 16px;
	font: inherit;
	font-size: 0.833em;
}

.react-calendar__tile:disabled {
	background-color: whitesmoke;
	color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
	color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #def0e6;
}

.react-calendar__tile--now {
	background: #28b67e;
	color: #ece9e9;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: #28b67e;
	color: #ece9e9;
}

.react-calendar__tile--hasActive {
	background: #1d4c4f;
	color: #ece9e9;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: #1d4c4f;
}

.react-calendar__tile--active {
	background: #1d4c4f;
	color: #ece9e9;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #163020;
}

.react-calendar--selectRange .react-calendar__tile--hover {
	background: #163020;
}

abbr {
	cursor: default;
	text-decoration: none;
}
